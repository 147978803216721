import React, { useState } from 'react';
import { Heart } from 'lucide-react';
import type { DailyArchive } from '../types';

const MOCK_ARCHIVE: DailyArchive[] = [
  {
    date: new Date('2024-03-10'),
    topPost: {
      id: '1',
      content: "Life isn't about waiting for the storm to pass, it's about learning to dance in the rain. Today I finally understood what that truly means.",
      timestamp: new Date('2024-03-10T14:23:00'),
      votes: 256,
      archived: true
    }
  },
  {
    date: new Date('2024-03-09'),
    topPost: {
      id: '2',
      content: "To the stranger who helped me change my tire today - you reminded me that there's still good in the world. Thank you.",
      timestamp: new Date('2024-03-09T16:45:00'),
      votes: 189,
      archived: true
    }
  },
  {
    date: new Date('2024-03-08'),
    topPost: {
      id: '3',
      content: "Found an old letter from my younger self today. It's amazing how our dreams evolve, yet stay fundamentally the same.",
      timestamp: new Date('2024-03-08T09:12:00'),
      votes: 203,
      archived: true
    }
  }
];

export default function Archive() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [archive] = useState<DailyArchive[]>(MOCK_ARCHIVE);

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }).format(date);
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-semibold text-gray-900">Archive</h2>
      
      <div className="grid gap-4">
        {archive.map(({ date, topPost }) => (
          <button
            key={date.toISOString()}
            onClick={() => setSelectedDate(selectedDate?.getTime() === date.getTime() ? null : date)}
            className="text-left"
          >
            <div className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start mb-4">
                <time className="text-sm font-medium text-indigo-600">
                  {formatDate(date)}
                </time>
                <div className="flex items-center space-x-1 text-gray-500">
                  <Heart className="h-4 w-4" />
                  <span className="text-sm">{topPost.votes}</span>
                </div>
              </div>
              
              <p className="text-gray-900 line-clamp-2">
                {topPost.content}
              </p>
              
              {selectedDate?.getTime() === date.getTime() && (
                <p className="mt-4 text-gray-600">
                  {topPost.content}
                </p>
              )}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
}