export function getCurrentPSTDate(): Date {
  const date = new Date();
  date.setHours(date.getHours() - 8); // Convert to PST
  return date;
}

export function formatRelativeTime(date: Date): string {
  const now = getCurrentPSTDate();

  const diffInHours = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60));
  
  if (diffInHours < 24) {
    if (diffInHours === 0) {
      const diffInMinutes = Math.floor((now.getTime() - date.getTime()) / (1000 * 60));
      if (diffInMinutes < 1) return 'Just now';
      if (diffInMinutes === 1) return '1 minute ago';
      if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    }
    return `${diffInHours}h ago`;
  }
}