import React, { useState } from 'react';
import { Heart } from 'lucide-react';

interface HeartButtonProps {
  initialVotes: number;
  onVote: (newCount: number) => void;
}

export default function HeartButton({ initialVotes, onVote }: HeartButtonProps) {
  const [isLiked, setIsLiked] = useState(false);
  const [votes, setVotes] = useState(initialVotes);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (isAnimating) return;
    
    setIsAnimating(true);
    setIsLiked(!isLiked);
    const newVotes = isLiked ? votes - 1 : votes + 1;
    setVotes(newVotes);
    onVote(newVotes);
    
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <button 
      onClick={handleClick}
      className="flex items-center space-x-1.5 group"
      aria-label={`${isLiked ? 'Unlike' : 'Like'} this post (${votes} likes)`}
    >
      <Heart 
        className={`h-5 w-5 transition-all duration-300 ${
          isAnimating ? 'scale-125' : 'scale-100'
        } ${
          isLiked 
            ? 'fill-rose-500 text-rose-500' 
            : 'text-gray-500 group-hover:text-rose-500'
        }`}
      />
      <span className={`font-medium ${
        isLiked ? 'text-rose-500' : 'text-gray-500 group-hover:text-rose-500'
      }`}>
        {votes}
      </span>
    </button>
  );
}