import React, { useState } from 'react';
import { PenSquare } from 'lucide-react';
import Header from './components/Header';
import PostList from './components/PostList';
import CreateWhisper from './components/CreateWhisper';
import Archive from './components/Archive';
import { Post } from './types';

type View = 'feed' | 'archive' | 'create';

function App() {
  const [currentView, setCurrentView] = useState<View>('feed');
  const [posts, setPosts] = useState<Post[]>([]);

  const handleNewPost = (post: Post) => {
    setPosts(prevPosts => [post, ...prevPosts]);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Header currentView={currentView} onViewChange={setCurrentView} />
      <main className="max-w-2xl mx-auto px-4 py-8">
        {currentView === 'feed' && <PostList initialPosts={posts} onPostsChange={setPosts} />}
        {currentView === 'archive' && <Archive />}
        {currentView === 'create' && (
          <CreateWhisper 
            onClose={() => setCurrentView('feed')}
            onPost={handleNewPost}
          />
        )}
        
        {currentView !== 'create' && (
          <button
            onClick={() => setCurrentView('create')}
            className="fixed bottom-6 right-6 p-4 bg-indigo-600 text-white rounded-full shadow-lg hover:bg-indigo-700 transition-colors duration-200"
            aria-label="Share your whisper"
          >
            <PenSquare className="h-6 w-6" />
          </button>
        )}
      </main>
    </div>
  );
}

export default App;