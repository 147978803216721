import React, { useState, useEffect } from 'react';
import type { Post } from '../types';
import HeartButton from './HeartButton';
import { formatRelativeTime, getCurrentPSTDate } from '../utils/dates';
import { sortPosts } from '../utils/posts';

const TODAY = getCurrentPSTDate();

const MOCK_POSTS: Post[] = [
  {
    id: '1',
    content: "Just discovered an amazing new programming language that's completely changing how I think about code. Can't wait to build something with it! 🚀",
    timestamp: new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate(), 10, 30),
    votes: 42
  },
  {
    id: '2',
    content: "Today I helped a stranger carry their groceries and they were so grateful. It's the small acts of kindness that make the world better. ❤️",
    timestamp: new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate(), 9, 15),
    votes: 156
  },
  {
    id: '3',
    content: "Just had the most inspiring conversation with a stranger at the coffee shop. Sometimes the best moments come unexpectedly! ☕️",
    timestamp: new Date(TODAY.getFullYear(), TODAY.getMonth(), TODAY.getDate(), 8, 45),
    votes: 78
  }
];

interface PostListProps {
  initialPosts: Post[];
  onPostsChange: (posts: Post[]) => void;
}

export default function PostList({ initialPosts, onPostsChange }: PostListProps) {
  const [posts, setPosts] = useState<Post[]>(() => 
    initialPosts.length > 0 ? initialPosts : MOCK_POSTS
  );
  const [sortedPosts, setSortedPosts] = useState<Post[]>([]);

  useEffect(() => {
    setSortedPosts(sortPosts(posts));
  }, [posts]);

  const handleVote = (postId: string, newVoteCount: number) => {
    const updatedPosts = posts.map(post => 
      post.id === postId ? { ...post, votes: newVoteCount } : post
    );
    setPosts(updatedPosts);
    onPostsChange(updatedPosts.filter(post => !MOCK_POSTS.some(mock => mock.id === post.id)));
  };
  
  return (
    <div className="space-y-6">
      <div className="sticky top-0 bg-gray-50/80 backdrop-blur-sm py-3 -mx-6 px-6">
        <time className="text-lg font-medium text-gray-900">
          December 2, 2024
        </time>
      </div>
      
      {sortedPosts.map((post) => (
        <article 
          key={post.id} 
          className="bg-white rounded-lg shadow-sm p-6 transition-all hover:shadow-md"
        >
          <p className="text-gray-900 text-lg leading-relaxed mb-4">
            {post.content}
          </p>
          
          <div className="flex items-center justify-between text-sm">
            <time 
              className="text-gray-500" 
              dateTime={post.timestamp.toISOString()}
              title={post.timestamp.toLocaleString()}
            >
              {formatRelativeTime(post.timestamp)}
            </time>
            
            <HeartButton 
              initialVotes={post.votes}
              onVote={(newCount) => handleVote(post.id, newCount)}
            />
          </div>
        </article>
      ))}
    </div>
  );
}