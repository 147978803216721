import React, { useState } from 'react';
import { X } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import type { Post } from '../types';
import { getCurrentPSTDate } from '../utils/dates';

interface CreateWhisperProps {
  onClose: () => void;
  onPost: (post: Post) => void;
}

const GUIDELINES = [
  'Be kind and respectful',
  'No personal information',
  'Keep it clean and appropriate',
  'Maximum 500 characters',
  'No spam or advertising'
];

export default function CreateWhisper({ onClose, onPost }: CreateWhisperProps) {
  const [content, setContent] = useState('');
  const [isPreview, setIsPreview] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newPost: Post = {
      id: uuidv4(),
      content: content.trim(),
      timestamp: getCurrentPSTDate(),
      votes: 0
    };
    onPost(newPost);
    onClose();
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">Share Your Whisper</h2>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-gray-600 rounded-full transition-colors duration-200"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="mb-6">
        <h3 className="text-sm font-medium text-gray-700 mb-2">Guidelines</h3>
        <ul className="text-sm text-gray-600 space-y-1">
          {GUIDELINES.map((guideline, index) => (
            <li key={index} className="flex items-center">
              <span className="w-1.5 h-1.5 bg-indigo-400 rounded-full mr-2" />
              {guideline}
            </li>
          ))}
        </ul>
      </div>

      <form onSubmit={handleSubmit}>
        {!isPreview ? (
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            className="w-full h-32 p-3 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            placeholder="Share your thoughts..."
            maxLength={500}
            required
          />
        ) : (
          <div className="w-full h-32 p-3 bg-gray-50 rounded-md overflow-y-auto">
            {content}
          </div>
        )}

        <div className="mt-2 text-sm text-gray-500">
          {500 - content.length} characters remaining
        </div>

        <div className="mt-6 flex justify-end space-x-3">
          <button
            type="button"
            onClick={() => setIsPreview(!isPreview)}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
          >
            {isPreview ? 'Edit' : 'Preview'}
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
          >
            Share Anonymously
          </button>
        </div>
      </form>
    </div>
  );
}